import fs from 'fs';
import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';

// eslint-disable-next-line import/prefer-default-export
export async function contentDataFor(path: string, config: NuxtRuntimeConfig = {}) {
  // On server-side, we can load the file directly.
  if (process.server) {
    const lib = await import('@/server/content-data/content-data-file-detector');
    const contentDataFilePath = await lib.determineBestContentDataPath(`/${path}`);
    try {
      const data = fs.readFileSync(contentDataFilePath, 'utf8');
      return JSON.parse(data);
    } catch (error: Error | any) {
      const message = `[Lib] contentdata file not found: "${contentDataFilePath}" path: "${path}" (${error.message})`;
      console.error(message);
      return {};
    }
  }

  // on client side we have to request the file
  try {
    const publicConfig = config.public || config;
    const response = await fetch(
      `${
        publicConfig.cloudFront?.contentDataEnabled ? publicConfig.cdnUrl : ''
      }/content-repo/content-json/${path}`
    ).then((r) => r.json());
    return response || {};
  } catch (error: Error | any) {
    const isProduction = config.public?.mode?.isProduction || config.mode?.isProduction;
    if (!isProduction) {
      console.error(error);
    }
    return {};
  }
}
