var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"homepage-highlights"},[_c(_setup.Heading,{staticClass:"homepage-highlights__heading",attrs:{"tag":"h2","size":"md","font-weight":"regular","spaces-bottom":""}},[_vm._v("\n    Unsere Highlights\n  ")]),_vm._v(" "),_c(_setup.DraggableScroller,{ref:"draggableScrollbar",staticClass:"homepage-highlights__list-wrapper",attrs:{"has-arrow":"","has-pagination":""}},_vm._l((_setup.highlights),function(highlight){return _c('div',{key:highlight.label,staticClass:"homepage-highlights__list-entry"},[_c('nuxt-link',{staticClass:"homepage-highlights__list-item btn btn--text btn--transparent",attrs:{"to":highlight.link}},[_c('div',{staticClass:"homepage-highlights__visual"},[_c('div',{staticClass:"homepage-highlights__item-image-wrapper",class:{
              'homepage-highlights__item-image-wrapper--goliath': highlight.offer?.product,
            },style:(_setup.getColorConstants(highlight))},[_c('div',{staticClass:"homepage-highlights__image-placeholder",class:{
                'homepage-highlights__image-placeholder--cover':
                  highlight.images?.behaviour === 'cover',
              }},[(highlight.badgeMessage)?_c(_setup.Badge,{staticClass:"homepage-highlights-badge text--bold",staticStyle:{"--badge-xs-size":"1.6em","--badge-xs-padding":"0.4em","--badge-sm-size":"1.7em","--badge-sm-padding":"0.5em"}},[_vm._v("\n                "+_vm._s(highlight.badgeMessage)+"\n              ")]):_vm._e(),_vm._v(" "),(!highlight.offer?.product)?[_c(_setup.CdnImage,{staticClass:"homepage-highlights__item-image",class:{
                    'homepage-highlights__item-image--before': highlight.images.hover?.src,
                  },attrs:{"alt":highlight.label,"src":highlight.images.initial.src,"src-sm":highlight.images.initial.srcSm,"lazy":""}}),_vm._v(" "),(highlight.images.hover?.src)?_c(_setup.CdnImage,{staticClass:"homepage-highlights__item-image homepage-highlights__item-image--after",attrs:{"alt":highlight.label,"src":highlight.images.hover.src,"src-sm":highlight.images.hover.srcSm,"lazy":""}}):_vm._e()]:[(highlight.offer.teaserImage?.sources)?_c(_setup.GoliathImageElement,{staticClass:"homepage-highlights__item-image homepage-highlights__item-image--before",staticStyle:{"aspect-ratio":"1"},attrs:{"alt":highlight.label,"sources":highlight.offer.teaserImage.sources,"xs-min-width":500,"xs-max-width":999,"sm-min-width":1000,"sm-max-width":1000,"lazy":""}}):_vm._e(),_vm._v(" "),(highlight.goliathHoverImage)?_c(_setup.GoliathImageElement,{staticClass:"homepage-highlights__item-image homepage-highlights__item-image--after",attrs:{"alt":highlight.label,"sources":highlight.goliathHoverImage,"xs-min-width":500,"xs-max-width":999,"sm-min-width":1000,"sm-max-width":1000,"lazy":""}}):_vm._e()],_vm._v(" "),(highlight.logo?.src)?_c(_setup.CdnImage,{staticClass:"homepage-highlights__image-logo",attrs:{"alt":highlight.logo.alt,"src":highlight.logo.src,"render-without-figure":"","lazy":""}}):_vm._e()],2)])]),_vm._v(" "),_c('span',{staticClass:"homepage-highlights__title"},[_vm._v("\n          "+_vm._s(highlight.label)+"\n          "),_c('span',{staticClass:"homepage-highlights__arrow-symbol"},[_c('SvgIcon',{attrs:{"image":_setup.arrowImage}})],1)])])],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }