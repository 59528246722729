
import { useMeta, useContext, useFetch, defineComponent, computed } from '@nuxtjs/composition-api';
import { ref } from 'vue';

import SustainableProducts from '@/components/homepage/sustainable-products.vue';
import BrandWallpaper from '@/components/brand-wallpaper.vue';
import ThemeTeasers from '@/components/homepage/theme-teasers.vue';
import Services from '@/components/homepage/services.vue';
import HomepageCategories from '@/components/homepage/homepage-categories.vue';
import HomepageHighlights from '@/components/homepage/homepage-highlights.vue';
import Promotions from '@/components/promotions.vue';
import RecommendedProducts from '@/components/recommended-products.vue';

import {
  Heading,
  LinkButton,
  GridContainer,
  GridRow,
  GridColumn,
  BannerTeaser,
} from '@i22-td-smarthome/component-library';
import { Track } from '@/helpers/page-tracking';
import { getHeader } from '@/helpers/page-header-compositon';
import { contentDataFor } from '@/lib/content-data-factory';
import { getActivePromotions, type StageSliderData } from '@/lib/promotions';
import type { Offer } from '@/lib/goliath/offers';
import type { PromotionOffer } from '@/lib/offers-by-group';

export default defineComponent({
  components: {
    SustainableProducts,
    BrandWallpaper,
    ThemeTeasers,
    Services,
    HomepageCategories,
    HomepageHighlights,
    Promotions,
    RecommendedProducts,
    Heading,
    LinkButton,
    GridContainer,
    GridRow,
    GridColumn,
    BannerTeaser,
  },
  head: {},
  setup: () => {
    Track({ page_type: 'theme.landing' });
    useMeta(
      getHeader('Smartphones, Tablets und Zubehör', {
        description:
          'Große Auswahl an Handys & Smartphones, Tablets und  Zubehör. Geprüfte Sicherheit ✓ Schnelle Lieferzeiten ✓ Jetzt entdecken und bestellen!',
      }) as any
    );

    const context = useContext();
    const promotions = ref<PromotionOffer<Offer, StageSliderData>[]>([]);

    const dateToday = computed(() =>
      new Date(context.store.getters['server-date/getServerDate']).getTime()
    );

    useFetch(async () => {
      try {
        const data = await contentDataFor('shared/promotions', context);
        if (!data?.promotions || !Array.isArray(data.promotions)) return;
        promotions.value = await getActivePromotions(data.promotions, dateToday.value);
      } catch (e) {
        console.error(e);
      }
    });

    return { promotions };
  },
});
