import {
  loadOffersForPromotions,
  type Promotion,
  type PromotionOffer,
} from '@/lib/offers-by-group';
import type { Offer } from '@/lib/goliath/offers';

export type CTA = {
  buttonText: string;
  url: string | undefined;
};

export type SlideVisual = {
  srcSmall: string | undefined;
  srcMedium?: string | undefined;
  srcLarge?: string | undefined;
  verticalAlignment?: string;
};

export type StageSliderData = {
  type: 'stage' | string;
  startDate?: string;
  endDate?: string;
  articleNumber?: string; // load offer data dynamically
  headline?: {
    text: string;
    tag?: string; // h2
    brand?: {
      src: string;
      alt: string;
      style?: string;
      sizes?: string;
    };
  };
  subline: string;
  customClass?: string;
  customStyle?: string; // --slider-arrow-left: magenta; --slider-arrow-right: magenta; --slider-arrow-size 2em;
  theme?: 'light' | 'dark'; // dark = default
  background?: {
    color: string;
    image?: {
      alt?: string;
      srcSmall?: string;
      srcMedium?: string;
      srcLarge?: string;
      sizes?: string;
    };
  };
  badge?: {
    text?: string;
    style?: string;
    magenta?: boolean;
    rebate?: boolean;
    position: {
      default?: { top: string; left: string };
      mobile?: { top: string; left: string };
    };
  };
  badgeModal?: string[];
  ctaButton?: CTA & {
    theme?: 'light' | 'dark';
    isExternal?: boolean;
    hideButton?: boolean;
  };
  secondaryCtaButton?: {
    buttonText: string;
    url: string;
    isExternal?: boolean;
  };
  visual?: SlideVisual & {
    srcSmall?: string;
    srcMedium?: string;
    alt?: string;
    sizes?: string;
    style?: string;
  };
  modal?: {
    id: string;
    heading: string;
    subheading: string;
    copy: string;
    button?: {
      link: string;
      label: string;
    };
    opener: ('footnote' | 'badge' | 'button')[];
  };
};

const parseDate = (date: string): Date | undefined => {
  const dateParts = date.match(/(\d+)/g);
  if (!dateParts || dateParts.length < 3) return undefined;
  return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
};

const getPromotionStartDate = (startDate: string | undefined): number => {
  // if undefined then startDate shouldn't matter
  // -> 0
  if (!startDate) return 0;
  return parseDate(startDate)?.getTime() || 0;
};
const getPromotionEndDate = (endDate: string | undefined): number => {
  // if undefined then endDate shouldn't matter
  // -> Infinity
  if (!endDate) return Infinity;
  return parseDate(endDate)?.getTime() || Infinity;
};

export const getActivePromotions = async (
  promotions: Promotion<StageSliderData>[],
  dateToday: number
): Promise<PromotionOffer<Offer, StageSliderData>[]> => {
  const activePromotions = promotions?.filter((promotion) => {
    if (promotion.type !== 'stage') return false;
    const startDate = getPromotionStartDate(promotion.startDate);
    const endDate = getPromotionEndDate(promotion.endDate);

    if (startDate > endDate) {
      throw new Error(`startDate can't be later then endDate for ${promotion.headline?.text}`);
    }

    return startDate < dateToday && endDate > dateToday;
  });

  return loadOffersForPromotions(activePromotions);
};
