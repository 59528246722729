var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"theme-teasers"},[_c(_setup.Heading,{staticClass:"theme-teasers__heading",attrs:{"tag":"h2","size":"md","font-weight":"regular","spaces-bottom":""}},[_vm._v("\n    Themenwelten\n  ")]),_vm._v(" "),_c(_setup.GridRow,{staticClass:"theme-teasers__list"},_vm._l((_setup.teasersData),function(teaser,id){return _c(_setup.GridColumn,{key:id,attrs:{"xs":12,"sm":6,"md":4}},[_c(_setup.MouseOverTeaser,{staticClass:"theme-teaser",class:teaser.backgroundClass,attrs:{"background-animation-class":teaser.backgroundAnimationClass,"background-style":teaser.backgroundStyle,"background-image":teaser.backgroundImage,"link":teaser.link},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(_setup.CdnImage,{staticClass:"theme-teaser__manufacturer-logo",style:(`${
              teaser.header.image.aspectRatio
                ? `--aspect-ratio:${teaser.header.image.aspectRatio}`
                : ''
            }`),attrs:{"src":teaser.header.image.src,"alt":teaser.header.image.alt,"render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.Heading,{staticClass:"theme-teaser__manufacturer-heading",attrs:{"font-weight":"ultra","tag":"h3","size":"md"}},[_vm._v("\n            "+_vm._s(teaser.header.heading)+"\n          ")])]},proxy:true},{key:"default",fn:function({ mouseOver }){return _vm._l((teaser.contentImages),function(image){return _c(_setup.CdnImage,{key:image.src,staticClass:"mouseover-teaser__element",class:{
              [image.animationClass]: mouseOver,
            },style:(image.styles),attrs:{"sizes":image.sizes,"src":image.src,"src-md":image.srcMd,"alt":image.alt,"render-without-figure":"","lazy":""}})})}}],null,true)})],1)}),1),_vm._v(" "),_c('nuxt-link',{staticClass:"theme-teasers__note",attrs:{"to":"/themenwelten"}},[_vm._v("\n    Weitere Themenwelten entdecken\n    "),_c('span',{staticClass:"theme-teasers__arrow-symbol"},[_c('SvgIcon',{attrs:{"image":_setup.arrowImage}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }