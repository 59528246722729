/* eslint-disable import/prefer-default-export */
export const teasersData = [
  {
    link: '/anker',
    backgroundAnimationClass: 'mouseover-teaser--rounded-rotate-bg',
    header: {
      image: {
        src: '/api/content/images/homepage/brands/2c901dc440a584ae02b1f0d57f6b714d/anker-logo.svg',
        alt: 'Anker',
        aspectRatio: 25 / 6,
      },
      heading: 'Die Nr.1 für mobiles Laden',
    },
    backgroundImage: {
      alt: 'Anker Ladegeräte',
      src: '/api/content/images/homepage/theme-teasers/1bf7d247738133107a006c8e0f11778d/anker-themenweltteaser-bg@2x.jpg',
      sizes: 'sm:100vw md:50vw lg:928px',
    },
    contentImages: [
      {
        src: '/api/content/images/homepage/theme-teasers/ed9192f25951f0fc4546dde13348fd57/anker-themenweltteaser-produkte@2x.png',
        alt: 'Anker Ladegeräte',
        styles:
          'justify-self: center; align-self: center; width: 80%; max-width: 565px; aspect-ratio: 836 / 675',
        animationClass: 'mouseover-teaser--scale',
        sizes: 'xs:80vw sm:80vw md:50vw lg:464px',
      },
    ],
  },
  {
    link: '/a-good-company',
    backgroundAnimationClass: 'mouseover-teaser--rounded-rotate-bg',
    header: {
      image: {
        src: '/api/content/images/homepage/brands/8afdfa6399ec67cae02b3f6a83540c02/agood.svg',
        alt: 'agood company',
        aspectRatio: 75 / 23,
      },
      heading: 'Schütze dein Smartphone und die Umwelt',
    },
    backgroundImage: {
      alt: 'agood company',
      src: '/api/content/images/homepage/theme-teasers/ec88e22714324dd5e23b84890cce72c7/agood-themenwelt-teaser-bg@2x.jpg',
      sizes: 'sm:100vw md:50vw lg:928px',
    },
    contentImages: [
      {
        src: '/api/content/images/homepage/theme-teasers/70baed837d7482161626ebc31739c99c/agood-case-no-plastic-green-magenta@2x.png',
        alt: 'agood company',
        styles:
          'align-self: center; justify-self: center; height: 80%;overflow: hidden; aspect-ratio: 338 / 539',
        animationClass: 'mouseover-teaser--scale',
        sizes: 'xs:80vw sm:80vw md:50vw lg:464px',
      },
    ],
  },
  {
    link: '/samsung',
    backgroundAnimationClass: 'mouseover-teaser--rounded-white',
    header: {
      image: {
        src: '/api/content/images/homepage/brands/4b26d02037f999674611ac6f7af3c5b6/samsung.svg',
        alt: 'Samsung',
        aspectRatio: 131 / 18,
      },
      heading: 'Die Galaxy Familie – eine perfekte Verbindung',
    },
    contentImages: [
      {
        src: '/api/content/images/homepage/theme-teasers/96ab877b5d867fed5b1b2e8876ae2dd9/teaser-samsung-geraete@2x.png',
        alt: 'Samsung Galaxy Familie',
        styles: 'align-self: end; aspect-ratio: 819 / 724; padding: 12px;',
        animationClass: 'mouseover-teaser--scale',
        sizes: 'xs:80vw sm:80vw md:50vw lg:464px',
      },
    ],
  },
];
