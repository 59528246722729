var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.GridContainer,{staticClass:"sustainable-products"},[_c(_setup.GridRow,[_c(_setup.Heading,{staticClass:"sustainable-products__heading",attrs:{"tag":"h2","size":"md","font-weight":"regular","spaces-bottom":""}},[_vm._v("Nachhaltige Produkte")])],1),_vm._v(" "),_c(_setup.GridRow,{staticClass:"sustainable-products__list"},[_c(_setup.GridColumn,{attrs:{"xs":12,"sm":6,"md":4}},[_c(_setup.MouseOverTeaser,{staticClass:"sustainable-teaser",attrs:{"link":"/geraete/fairphone-fairbuds-weiss"},scopedSlots:_vm._u([{key:"default",fn:function({ mouseOver }){return [_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element center",class:{
              'mouseover-teaser--scale': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/e212b00343baec95ff6256153db8f6ab/fairphone-fairbuds.png","src-md":"/api/content/images/homepage/sustainable-products/d0a3a5811916f98c466d8f6f304b554a/fairphone-fairbuds@2x.png","alt":"Fairphone Fairbuds","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-2",class:{
              'mouseover-teaser--move-left': mouseOver,
              'mouseover-teaser--rotate-left': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/94fb578a1c96ef15b23b3940a26bd1d1/leaf-2.svg","alt":"","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-1",class:{
              'mouseover-teaser--move-left': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/757d70b6039dcda52a6ab5c07ebed48a/leaf-1.svg","alt":"","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-3",class:{
              'mouseover-teaser--move-right': mouseOver,
              'mouseover-teaser--rotate-right': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/49574789550c4cec1f5b1321a276b3d3/leaf-3.svg","alt":"","render-without-figure":"","lazy":""}})]}}])})],1),_vm._v(" "),_c(_setup.GridColumn,{attrs:{"xs":12,"sm":6,"md":4}},[_c(_setup.MouseOverTeaser,{staticClass:"sustainable-teaser sustainable-teaser--dark",attrs:{"link":"/geraete/fairphone-5-256-gb-plus-8-gb-sky-blue"},scopedSlots:_vm._u([{key:"default",fn:function({ mouseOver }){return [_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element",class:{
              'mouseover-teaser--scale': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/89729a57ccc230e45def525f8dbfc8af/fairphone-5.png","src-md":"/api/content/images/homepage/sustainable-products/9c1c2c72bbf47c97fd1d6767cfb3423a/fairphone-5@2x.png","alt":"Fairphone 5","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-4",class:{
              'mouseover-teaser--move-left': mouseOver,
              'mouseover-teaser--rotate-left': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/3e36f78e96d6b8aa34c9044bf27ace1c/leaf-4.svg","alt":"","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-5",class:{
              'mouseover-teaser--move-right': mouseOver,
              'mouseover-teaser--rotate-right': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/9c1adb76632e018a58386be330a11c27/leaf-5.svg","alt":"","render-without-figure":"","lazy":""}})]}}])}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"sustainable-teaser__container-badge",attrs:{"src":"/api/content/images/homepage/sustainable-products/bc42f372014c0e2ec4df6e86c82cfa6b/green-magenta-badge.svg","alt":"Green Magenta","render-without-figure":"","lazy":""}})],1),_vm._v(" "),_c(_setup.GridColumn,{staticClass:"sustainable-teaser__container",attrs:{"xs":12,"sm":6,"md":4}},[_c(_setup.MouseOverTeaser,{staticClass:"sustainable-teaser sustainable-teaser--light",attrs:{"link":"/geraete/syllucid-universal-usb-ladekabel-mit-reisebox-12m-12m-grau"},scopedSlots:_vm._u([{key:"default",fn:function({ mouseOver }){return [_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element",class:{
              'mouseover-teaser--scale': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/1f0de8c355e17d6c41e95561efba28e5/syllucid-ladekabel.png","src-md":"/api/content/images/homepage/sustainable-products/6b23672bcc1fb51286b169ee6f6c45ed/syllucid-ladekabel@2x.png","alt":"Syllucid Universal USB Ladekabel mit Reisebox","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-6",class:{
              'mouseover-teaser--move-left': mouseOver,
              'mouseover-teaser--rotate-left': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/6b6f9be6b26e9de592033f830ba1ebe0/leaf-6.svg","alt":"","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-7",class:{
              'mouseover-teaser--move-right': mouseOver,
              'mouseover-teaser--rotate-left': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/57b5395dea429aebfa254dc5aabf5fd9/leaf-7.svg","alt":"","render-without-figure":"","lazy":""}}),_vm._v(" "),_c(_setup.CdnImage,{staticClass:"mouseover-teaser__element leaf-8",class:{
              'mouseover-teaser--move-right': mouseOver,
              'mouseover-teaser--rotate-left': mouseOver,
            },attrs:{"src":"/api/content/images/homepage/sustainable-products/a8059e592deccd252f5ba26f2151d8c3/leaf-8.svg","alt":"","render-without-figure":"","lazy":""}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }